@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

.container {
  /* max-width: 100%;
  max-height: 100%; */
  /* margin: 30px auto;
  overflow: auto;
  min-height: 300px; */
  /* border: 1px solid steelblue;
  padding: 30px;
  border-radius: 5px; */
  /* background-image: url("./assets/seattle_background.jpg"); */
  /* background-color: rgb(215, 243, 221); */
}

.header {
  /* display: flex; */
  /* justify-content: space-between; */
  max-width: 100%;
  min-height: 100px;
  border: 1px solid steelblue;
  vertical-align: middle;
  /* line-height: 50px; */
  padding: 15px;
  align-items: center;
  text-align: center;
  font-size: 15px;
  
}

.introduction{
  align-items: center;
  text-align: center;
  padding: 10px 30px;
  margin-bottom: 1em; 

}

/* h1 {
  font-size: 10px;
  text-align: center;
} */

.bg-header {
  background-image: url(/static/media/seattle_picture.ffd74b2e.jpg);
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-introduction {
  background-color: rgb(34, 139, 131);
  

  /* Full height */
  /* height: 100%; */

  /* Center and scale the image nicely */
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover;  */
}

.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.task {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.task.reminder {
  border-left: 5px solid green;
}

.task h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-form {
  margin-bottom: 40px;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1 1;
}

.form-control-check input {
  flex: 2 1;
  height: 20px;
}

footer {
  margin-top: 30px;
  text-align: center;
}
figure {
  border: 1px #cccccc solid;
  padding: 4px;
  margin: auto;
  max-height: 200px;
}

figcaption {
  background-color: black;
  color: white;
  font-style: italic;
  padding: 2px;
  text-align: center;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-size: 13px;
}

.social-icon {
  /* width: 20px;
  height: 20px; */
  margin: 0 10px;
 }


a.div_a {
  
  display: table-cell;
  vertical-align: middle;
  background-color:#CCC;
  float:left;
  border: 1px solid steelblue;
  padding: 10px;
  text-align: center;
  height: 370px;
}

.wrapper {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  

}
  .Projects {
    align-items: center;
    background-color: rgb(207, 238, 250);
  }

  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }


  .yellow_back_2 {
    background-color: rgb(207, 238, 250);
  }


  .seattle_back {
    background-image: url(/static/media/seattle_picture.ffd74b2e.jpg);
    background-position-x: 5%;
    background-position-y: 40%;
  }

  /* Rounded border */
hr.rounded {
  border-top: 8px solid rgb(172, 85, 85);
  border-radius: 5px;
}

canvas {
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 800px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

